import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable, filter, map, take, tap } from 'rxjs';
import { AuthService } from '@app-core/auth/auth.service';
import { UserSettingsService } from '@app-core/auth/user-settings.service';
import { LocationSettingsService } from '@app-core/auth/location-settings.service';
import { LocationResponse } from '@app-autogen/ez.common/contracts/response/location-response';

import { EzFormControl } from '@ez-packages';
import { EzLocaleHelper } from '@ezuiaws/ez-packages/ez-localization';
import { EzNotificationService } from '@ezuiaws/ez-packages/ez-notification';
import { EZLayoutService } from '@ezuiaws/ez-packages/ez-layout';
import { ToastContainers } from '../models/toast-containers.enum';
import { BannerNotificationModel, NotificationModel, NotificationScope } from '@ezuiaws/ez.ui.models';
import { BannerContentAlignment } from '@ezuiaws/ez-packages/ez-banner';
import { Router } from '@angular/router';
import { EzStoreService } from '@ezuiaws/ez-packages/ez-store';
import * as appRouter from '@src/app/state/router-state';
import { SharedStateFacade } from '@app-root/state/shared-state/shared-state.facade';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {

  @Input() isMobileDevice: boolean = false;

  pageTitle$: Observable<string> = this.layoutService.pageTitle$;

  headerForm: UntypedFormGroup;

  userLocationId: number = this.userSettingsService.userSession.currentLocationId;
  enableChatSupport: boolean = this.locationSettingsService.getLocationPreferenceBooleanValue('EnableChatSupport');

  toastContainerId: ToastContainers = ToastContainers.LayoutBottomHeader;

  banners$: Observable<NotificationModel[]> = this.notificationService.banners$;
  banners: NotificationModel[] = [];

  userHasPendingActivityItem$: Observable<boolean> = this.sharedStateFacade.userHasPendingActivityItem$;

  showConfirmationOnLocationChange$: Observable<boolean> = this.layoutService.showConfirmationOnLocationChange$;

  BannerContentAlignment = BannerContentAlignment;
  NotificationScope = NotificationScope;
  BannerNotificationModel = BannerNotificationModel;

  sessionUserId: number = this.userSettingsService.userSession.userId;

  constructor(
    private authService: AuthService,
    private locationSettingsService: LocationSettingsService,
    private userSettingsService: UserSettingsService,
    private fb: UntypedFormBuilder,
    private notificationService: EzNotificationService,
    private layoutService: EZLayoutService,
    private router: Router,
    private storeHelper: EzStoreService<appRouter.State>,
    private sharedStateFacade: SharedStateFacade
  ) { }

  ngOnInit() {
    this.headerForm = this.fb.group({
      locationId: new EzFormControl({ value: this.userLocationId, disabled: true }, null, null, 'locationId')
    });

    this.notificationService.defaultNotificationContainerId = this.toastContainerId;
    this.notificationService.setInlineNotificationContainer(this.toastContainerId);
    this.subscribeOnGlobalBanners();
    this.subscribeOnUserPendingActivityItem();

  }

  subscribeOnGlobalBanners() {
    this.banners$.pipe(
      filter((banners: NotificationModel[]) => !!banners.length),
      map((banners: NotificationModel[]) => {
        return banners.filter(banner => banner.Enabled && !!banner.NotificationText.trim());
      }),
      take(1)
    ).subscribe((data) => {
      this.banners = this.userSettingsService.getUserPreferenceBooleanValue('ShowGlobalNotification') ?
        data :
        data.filter(banner => banner.NotificationType !== BannerNotificationModel.Survey);
    });
  }

  subscribeOnUserPendingActivityItem() {
    this.storeHelper.stateStreamSelector(appRouter.getRouterFeatureState)
      .pipe(
        untilDestroyed(this),
        map(route => route.state.url)
        , tap(() => {
          this.sharedStateFacade.userHasPendingActivityItem(this.sessionUserId);
        }))
      .subscribe();
  }

  toggleMenu() {
    this.layoutService.toggleSidebar();
  }

  openHelpPage() {
    window.open('https://ezfacility.zendesk.com/hc/en-us', '_blank');
  }

  selectedLocationChanged(location: LocationResponse) {
    EzLocaleHelper.setCurrentLanguageLocale(location.cultureCode);
    void this.authService.changeUserLocation(location.locationId);
  }

  openZenDeskWidget() {
    if (typeof window['zE'] !== 'undefined') {
      window['zE']('messenger', 'open');
    }
  }

  canSwitchLocation(canSwitchLocation: boolean) {
    if (canSwitchLocation) {
      this.headerForm.controls['locationId'].enable();
    } else {
      this.headerForm.controls['locationId'].disable();
    }
  }

  onSurveyClicked() {
    this.userSettingsService.updateUserPreferenceBooleanValue('ShowGlobalNotification', false);
    this.banners = this.banners.filter(banner => banner.NotificationType !== BannerNotificationModel.Survey);
    this.notificationService.removeSurveyBanner();
  }

  onNotificationIconClick() {
    this.router.navigateByUrl(`/reports/membership-reports/client-activity-items-report/${this.sessionUserId}`)
  }


}
