import { TimeUnit } from '../../autogen/ez.common/contracts/request/time-unit';
import { Moment, MomentInput } from 'moment-timezone';
import * as momentTz from 'moment-timezone';

import { EzLocaleHelper } from '@ezuiaws/ez-packages/ez-localization';

export function getDateTimeInUserTimeZone(date?: Date | number | string, timezone?: string, localeCulture?: string): string {
  const dateObject: Date = date ? new Date(date) : new Date();
  timezone = timezone ?? EzLocaleHelper.timezone;
  localeCulture = localeCulture ?? 'en-US';

  const dateTimeInUserTimeZone: string = dateObject.toLocaleString(localeCulture, { timeZone: timezone });
  return getDateTimeInISOFormat(dateTimeInUserTimeZone);
}

/**
 * If the Z is present, the Date is set to UTC. If the Z is not present, it's set to local time
 * @param date
 */
export function getDateTimeInISOFormat(date: string | number | Date): string {
  const dateObject: Date = date ? new Date(date) : new Date();

  const offSet: number = new Date(dateObject).getTimezoneOffset() * (-1) * (60000);
  return new Date(dateObject.getTime() + offSet).toISOString().replace(/\..*?Z$/, '');
}

export function convertDateTimeFromUTCtoISO(date: string): string {
  return new Date(date).toISOString().replace(/\..*?Z$/, '');
}

export function convertDateTimeFormatToUTC(date: string): string {
  if (date.slice(-1) !== 'Z') {
    return `${date}Z`;
  }
  return date;
}

export function isHour12(): boolean {
  const options: Intl.ResolvedDateTimeFormatOptions = new Intl.DateTimeFormat(EzLocaleHelper.locale, {
    hour: 'numeric',
  }).resolvedOptions();

  return !!options.hour12;
}

export function formatDateToStringAndKeepTimeZone(date: Date): string {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

// UNUSED
/*export function getOffSetInMilliseconds(timeZone: string, date: Date): number {
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone: timeZone }));
  return (tzDate.getTime() - utcDate.getTime()) / 60000;
}

export function UTCToLocationTime(date: Date | string, timeZone, format?: string) {
  return momentTz.utc(date).tz(timeZone).format(format);
}

export function getWeekDay(date: Date | string): number {
  return momentTz.utc(date).weekday();
}

export function getMonth(date: Date | string): number {
  return momentTz.utc(date).month();
}

export function getYear(date: Date | string): number {
  return momentTz.utc(date).year();
}

export function getStartOf(timeZone, format?: string, date?: Date | string, unit: momentTz.unitOfTime.StartOf = 'day') {
  let momentStr;
  if (format) {
    if (date) {
      momentStr = momentTz.utc(date).tz(timeZone).startOf(unit).format(format);
    } else {
      momentStr = momentTz.utc().tz(timeZone).startOf(unit).format(format);
    }
  } else {
    if (date) {
      momentStr = momentTz.utc(date).tz(timeZone).startOf(unit).format();
    } else {
      momentStr = momentTz.utc().tz(timeZone).startOf(unit).format();
    }
  }

  return momentStr;
}

export function getEndOf(timeZone, format?: string, date?: Date | string, unit: momentTz.unitOfTime.StartOf = 'day') {
  let momentStr;
  if (format) {
    if (date) {
      momentStr = momentTz.utc(date).tz(timeZone).endOf(unit).format(format);
    } else {
      momentStr = momentTz.utc().tz(timeZone).endOf(unit).format(format);
    }
  } else {
    if (date) {
      momentStr = momentTz.utc(date).tz(timeZone).endOf(unit).format();
    } else {
      momentStr = momentTz.utc().tz(timeZone).endOf(unit).format();
    }
  }

  return momentStr;
}*/

export function getNextDateByTimeUnit(startDate: MomentInput, termLengthTimeUnit: TimeUnit, termLengthQuantity: number): string {

  let resultDate: Moment = momentTz.utc(startDate);

  switch (termLengthTimeUnit) {
    case TimeUnit.Second:
      resultDate = momentTz.utc(resultDate).add(termLengthQuantity, 's');
      break;
      case TimeUnit.Minute:
      resultDate = momentTz.utc(resultDate).add(termLengthQuantity, 'm');
      break;
      case TimeUnit.Hour:
      resultDate = momentTz.utc(resultDate).add(termLengthQuantity, 'h');
      break;
    case TimeUnit.Day:
      resultDate = momentTz.utc(resultDate).add(termLengthQuantity, 'd');
      break;
    case TimeUnit.Week:
      resultDate = momentTz.utc(resultDate).add(termLengthQuantity, 'w');
      break;
    case TimeUnit.Month:
      resultDate = momentTz.utc(resultDate).add(termLengthQuantity, 'M');
      break;
    case TimeUnit.HalfMonth:
      resultDate = momentTz.utc(resultDate).add(termLengthQuantity * 2, 'w');
      break;
    case TimeUnit.Year:
      resultDate = momentTz.utc(resultDate).add(termLengthQuantity, 'y');
      break;
    default:
      resultDate = momentTz.utc(resultDate);
  }
  return resultDate.toISOString();
}
