import { LocationResponse } from '@app-autogen/ez.common/contracts/response/location-response';
import { TrainerActionTypes } from '@app-autogen/ez.common/contracts/action-types/trainer-action-types';
import { LocationActionTypes } from '@app-autogen/ez.common/contracts/action-types/location-action-types';
import { SalesPeopleActionTypes } from '@app-autogen/ez.common/contracts/action-types/sales-people-action-types';
import { LabelValueViewModel } from '@app-autogen/ez.common/contracts/response/label-value-view-model';
import { SMSProviderActionTypes } from '@app-autogen/ez.common/contracts/action-types/sms-provider-action-types';
import { UserActionTypes } from '@app-autogen/ez.common/contracts/action-types/user-action-types';
import { CheckInStationActionTypes } from '@app-autogen/ez.common/contracts/action-types/check-in-station-action-types';
import { createActionHelper } from '@ezuiaws/ez-packages/ez-store';
import { ProgramFeeActionTypes } from '@app-autogen/ez.common/contracts/action-types/program-fee-action-types';
import { ResourceActionTypes } from '@app-autogen/ez.common/contracts/action-types/resource-action-types';
import { ProgramFeeResponse } from '@app-autogen/ez.common/contracts/response/program-fee-response';
import { TaxActionTypes } from '@app-autogen/ez.common/contracts/action-types/tax-action-types';
import { TaxResponse } from '@app-autogen/ez.common/contracts/response/tax-response';
import { TaxesRequest } from '@app-autogen/ez.common/contracts/request/taxes-request';
import { LocationContactPreferencesResponse } from '@app-autogen/ez.common/contracts/response/location-contact-preferences-response';
import { ResourceSelectResponse } from '@app-autogen/ez.common/contracts/response/resource-select-response';
import { ReservationTypeResponse } from '@app-autogen/ez.common/contracts/response/reservation-type-response';
import { ReservationTypeActionTypes } from '@app-autogen/ez.common/contracts/action-types/reservation-type-action-types';
import { TShirtSizesActionTypes } from '@app-autogen/ez.common/contracts/action-types/t-shirt-sizes-action-types';
import { MembershipActionTypes } from '@app-autogen/ez.membership/contracts/action-types/membership-action-types';
import { LocationJobClassificationActionTypes } from '@app-autogen/ez.common/contracts/action-types//location-job-classification-action-types';
import { LockersModuleActionTypes } from '@app-autogen/ez.locker/contracts/action-types/lockers-module-action-types';
import { LockerRoomResponse } from '@app-autogen/ez.locker/contracts/response/locker-room-response';
import { MembershipPlanActionTypes } from '@app-autogen/ez.membership/contracts/action-types/membership-plan-action-types';
import { MembershipTypeResponse } from '@app-autogen/ez.common/contracts/response/membership-type-response';
import { MembershipPlanResponse } from '@app-autogen/ez.common/contracts/response/membership-plan-response';
import { MonitorCoreServiceActionTypes } from '@app-autogen/ez.core/contracts/monitor/action-types/monitor-core-service-action-types';
import { MonitorPerformanceInfoResponse } from '@app-autogen-core-models/monitor/response/monitor-performance-info-response';
import { ErrorLoggingActionTypes } from '@app-autogen/ez.errorlogging/contracts/action-types/error-logging-action-types';
import { EventLogResponse } from '@app-autogen/ez.errorlogging/contracts/response/event-log-response';
import { UiWebActionTypes } from '@app-autogen-core-models/ui-web/action-types/ui-web-action-types';
import { UiWebResponse } from '@app-autogen-core-models/ui-web/response/ui-web-response';
import { GetLocationCustomFieldsResponse } from '@app-autogen/ez.common/contracts/response/get-location-custom-fields-response';
import { PointOfSaleEzActionTypes } from '@app-autogen/ez.point-of-sale/contracts/action-types/point-of-sale-ez-action-types';
import { GetCashRegistersResponse } from '@app-autogen/ez.point-of-sale/contracts/response/get-cash-registers-response';
import { UpdateUserPreferenceRequest } from '@app-autogen/ez.common/contracts/request/update-user-preference-request';
import { EZLeaguesActionTypes } from '@app-autogen/ez.ezleagues/contracts/action-types/ez-leagues-action-types';
import { EZLeaguesUserResponse } from '@app-autogen/ez.ezleagues/contracts/response/ez-leagues-user-response';
import { UserHasPendingActivityResponse } from '@app-autogen/ez.common/contracts/response/user-has-pending-activity-response';

export const getTrainersOnLoad = createActionHelper(TrainerActionTypes.GetTrainersOnLoad);
export const getTrainersOnLoadSuccess = createActionHelper<LabelValueViewModel[]>(TrainerActionTypes.GetTrainersOnLoadSuccess);
export const getTrainersOnLoadFail = createActionHelper<string>(TrainerActionTypes.GetTrainersOnLoadFail);

export const getSalesPeopleOnLoad = createActionHelper(SalesPeopleActionTypes.GetSalesPeopleOnLoad);
export const getSalesPeopleOnLoadSuccess = createActionHelper<LabelValueViewModel[]>(SalesPeopleActionTypes.GetSalesPeopleOnLoadSuccess);
export const getSalesPeopleOnLoadFail = createActionHelper<string>(SalesPeopleActionTypes.GetSalesPeopleOnLoadFail);

export const getLocationsOnLoad = createActionHelper(LocationActionTypes.GetLocationsOnLoad);
export const getLocationsOnLoadSuccess = createActionHelper<LocationResponse[]>(LocationActionTypes.GetLocationsOnLoadSuccess);
export const getLocationsOnLoadFail = createActionHelper<string>(LocationActionTypes.GetLocationsOnLoadFail);

export const saveLocationsOnClick = createActionHelper<LocationResponse[]>(LocationActionTypes.SaveLocationsOnClick);
export const saveLocationsOnClickSuccess = createActionHelper<LocationResponse[]>(LocationActionTypes.SaveLocationsOnClickSuccess);
export const saveLocationsOnClickFail = createActionHelper<string>(LocationActionTypes.SaveLocationsOnClickSuccess);

export const getSMSProvidersOnLoad = createActionHelper(SMSProviderActionTypes.GetSMSProvidersOnLoad);
export const getSMSProvidersOnLoadSuccess = createActionHelper<LabelValueViewModel[]>(SMSProviderActionTypes.GetSMSProvidersOnLoadSuccess);
export const getSMSProvidersOnLoadFail = createActionHelper<string>(SMSProviderActionTypes.GetSMSProvidersOnLoadFail);

export const updateUserPreference = createActionHelper<UpdateUserPreferenceRequest>(UserActionTypes.UpdateUserPreference);
export const updateUserPreferenceSuccess = createActionHelper(UserActionTypes.UpdateUserPreferenceSuccess);
export const updateUserPreferenceFail = createActionHelper<string>(UserActionTypes.UpdateUserPreferenceFail);

export const getLocationInfoOnLoad = createActionHelper(LocationActionTypes.GetLocationInfoOnLoad);
export const getLocationInfoOnLoadSuccess = createActionHelper<LocationResponse>(LocationActionTypes.GetLocationInfoOnLoadSuccess);
export const getLocationInfoOnLoadFail = createActionHelper<string>(LocationActionTypes.GetLocationInfoOnLoadFail);

export const getCheckInStationsOnLoad = createActionHelper(CheckInStationActionTypes.GetCheckInStationsOnLoad);
export const getCheckInStationsOnLoadSuccess = createActionHelper<LabelValueViewModel[]>(CheckInStationActionTypes.GetCheckInStationsOnLoadSuccess);
export const getCheckInStationsOnLoadFail = createActionHelper<string>(CheckInStationActionTypes.GetCheckInStationsOnLoadFail);

export const getProgramFees = createActionHelper(ProgramFeeActionTypes.GetProgramFees);
export const getProgramFeesSuccess = createActionHelper<ProgramFeeResponse[]>(ProgramFeeActionTypes.GetProgramFeesSuccess);
export const getProgramFeesFail = createActionHelper<string>(ProgramFeeActionTypes.GetProgramFeesFail);

export const getTaxes = createActionHelper<TaxesRequest>(TaxActionTypes.GetTaxes);
export const getTaxesSuccess = createActionHelper<TaxResponse[]>(TaxActionTypes.GetTaxesSuccess);
export const getTaxesFail = createActionHelper<string>(TaxActionTypes.GetTaxesFail);

export const getLocationContactPreferencesOnLoad = createActionHelper(LocationActionTypes.GetLocationContactPreferencesOnLoad);
export const getLocationContactPreferencesOnLoadSuccess = createActionHelper<LocationContactPreferencesResponse[]>(LocationActionTypes.GetLocationContactPreferencesOnLoadSuccess);
export const getLocationContactPreferencesOnLoadFail = createActionHelper<string>(LocationActionTypes.GetLocationContactPreferencesOnLoadFail);

export const getResourcesAndScheduleGroups = createActionHelper(ResourceActionTypes.GetResourcesAndScheduleGroups);
export const getResourcesAndScheduleGroupsSuccess = createActionHelper<ResourceSelectResponse[]>(ResourceActionTypes.GetResourcesAndScheduleGroupsSuccess);
export const getResourcesAndScheduleGroupsFail = createActionHelper<string>(ResourceActionTypes.GetResourcesAndScheduleGroupsFail);

export const getReservationTypesOnLoad = createActionHelper(ReservationTypeActionTypes.GetReservationTypesOnLoad);
export const getReservationTypesOnLoadSuccess = createActionHelper<ReservationTypeResponse[]>(ReservationTypeActionTypes.GetReservationTypesOnLoadSuccess);
export const getReservationTypesOnLoadFail = createActionHelper<string>(ReservationTypeActionTypes.GetReservationTypesOnLoadFail);

export const getTShirtSizesOnLoad = createActionHelper(TShirtSizesActionTypes.GetTShirtSizesOnLoad);
export const getTShirtSizesOnLoadSuccess = createActionHelper<LabelValueViewModel[]>(TShirtSizesActionTypes.GetTShirtSizesOnLoadSuccess);
export const getTShirtSizesOnLoadFail = createActionHelper<string>(TShirtSizesActionTypes.GetTShirtSizesOnLoadFail);

export const getMembershipTypesOnLoad = createActionHelper(MembershipActionTypes.GetMembershipTypesOnLoad);
export const getMembershipTypesOnLoadSuccess = createActionHelper<MembershipTypeResponse[]>(MembershipActionTypes.GetMembershipTypesOnLoadSuccess);
export const getMembershipTypesOnLoadFail = createActionHelper<string>(MembershipActionTypes.GetMembershipTypesOnLoadFail);

export const getMembershipPlansOnLoad = createActionHelper(MembershipPlanActionTypes.GetMembershipPlansOnLoad);
export const getMembershipPlansOnLoadSuccess = createActionHelper<MembershipPlanResponse[]>(MembershipPlanActionTypes.GetMembershipPlansOnLoadSuccess);
export const getMembershipPlansOnLoadFail = createActionHelper<string>(MembershipPlanActionTypes.GetMembershipPlansOnLoadFail);

export const getLocationJobTypesOnLoad = createActionHelper(LocationJobClassificationActionTypes.GetLocationJobClassificationsOnLoad);
export const getLocationJobTypesOnLoadSuccess = createActionHelper<LabelValueViewModel[]>(LocationJobClassificationActionTypes.GetLocationJobClassificationsOnLoadSuccess);
export const getLocationJobTypesOnLoadFail = createActionHelper<string>(LocationJobClassificationActionTypes.GetLocationJobClassificationsOnLoadFail);
// TODO duplicate of locker-rooms.actions.ts
export const getLockerRooms = createActionHelper(LockersModuleActionTypes.GetLockerRooms);
export const getLockerRoomsSuccess = createActionHelper<LockerRoomResponse[]>(LockersModuleActionTypes.GetLockerRoomsSuccess);
export const getLockerRoomsFail = createActionHelper<string>(LockersModuleActionTypes.GetLockerRoomsFail);
//
export const getDashboardOnLoad = createActionHelper(MonitorCoreServiceActionTypes.GetLatestMonitorPerformanceInfo);
export const getDashboardOnLoadSuccess = createActionHelper<MonitorPerformanceInfoResponse>(MonitorCoreServiceActionTypes.GetLatestMonitorPerformanceInfoSuccess);
export const getDashboardOnLoadFail = createActionHelper(MonitorCoreServiceActionTypes.GetLatestMonitorPerformanceInfoFail);

export const getDashboardWithTime = createActionHelper(MonitorCoreServiceActionTypes.GetTimeByRange);
export const getDashboardWithTimeSuccess = createActionHelper<MonitorPerformanceInfoResponse>(MonitorCoreServiceActionTypes.GetTimeByRangeSuccess);
export const getDashboardWithTimeFail = createActionHelper(MonitorCoreServiceActionTypes.GetTimeByRangeFail);

export const getEventLogOnLoad = createActionHelper(ErrorLoggingActionTypes.GetLatestEventLog);
export const getEventLogOnLoadSuccess = createActionHelper<EventLogResponse>(ErrorLoggingActionTypes.GetLatestEventLogSuccess);
export const getEventLogOnLoadFail = createActionHelper(ErrorLoggingActionTypes.GetLatestEventLogFail);

export const getEventLogTimeByRange = createActionHelper(ErrorLoggingActionTypes.GetEventLogTimeByRange);
export const getEventLogTimeByRangeSuccess = createActionHelper<EventLogResponse>(ErrorLoggingActionTypes.GetEventLogTimeByRangeSuccess);
export const getEventLogTimeByRangeFail = createActionHelper(ErrorLoggingActionTypes.GetEventLogTimeByRangeFail);

export const getUiWebOptions = createActionHelper(UiWebActionTypes.GetUIOptions);
export const getUiWebOptionsSuccess = createActionHelper<UiWebResponse>(UiWebActionTypes.GetUIOptionsSuccess);
export const getUiWebOptionsFail = createActionHelper(UiWebActionTypes.GetUIOptionsFail);

export const getCustomFields = createActionHelper(LocationActionTypes.GetCustomFields);
export const getCustomFieldsSuccess = createActionHelper<GetLocationCustomFieldsResponse>(LocationActionTypes.GetCustomFieldsSuccess);
export const getCustomFieldsFail = createActionHelper<string>(LocationActionTypes.GetCustomFieldsFail);

export const getCashRegisters = createActionHelper(PointOfSaleEzActionTypes.GetCashRegisters);
export const getCashRegistersSuccess = createActionHelper<GetCashRegistersResponse>(PointOfSaleEzActionTypes.GetCashRegistersSuccess);
export const getCashRegistersFail = createActionHelper<string>(PointOfSaleEzActionTypes.GetCashRegistersFail);

export const getEZLUser = createActionHelper<number>(EZLeaguesActionTypes.GetEZLeaguesUser);
export const getEZLUserSuccess = createActionHelper<EZLeaguesUserResponse>(EZLeaguesActionTypes.GetEZLeaguesUserSuccess);

export const hasUserPendingActivityItem = createActionHelper<number>(UserActionTypes.UserHasPendingActivityItem);
export const hasUserPendingActivityItemSuccess = createActionHelper<UserHasPendingActivityResponse>(UserActionTypes.UserHasPendingActivityItemSuccess);
export const hasUserPendingActivityItemFail = createActionHelper<null>(UserActionTypes.UserHasPendingActivityItemFail);

