import { Directive, ElementRef, Renderer2, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[ezRepositionFloatingElement]'
})
export class RepositionFloatingElementDirective implements OnChanges {

  @Input() top: number = null;
  @Input() left: number = null;
  @Input() containerMaxHeight: number = null;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) { }

  /* Get vieport width and height. Calculate floating element right and bottom perimeter to check if it bigger then viewport values.
     Based on condition element's top, right, bottom, left style values will be overwritten 
  */
  private repositionfloatingElement() {
    const viewportWidth: number = window.innerWidth || document.documentElement.clientWidth;
    const viewportHeight: number = window.innerHeight || document.documentElement.clientHeight;
    const floatingElementWidth: number = this.elementRef.nativeElement.offsetWidth;
    const floatingElementHeight: number = this.elementRef.nativeElement.offsetHeight;
    const floatingElementLeft: number = this.left;
    const floatingElementTop: number = this.top;
    const floatingElementRightPerimeter: number = floatingElementLeft + floatingElementWidth;
    const floatingElementBottomPerimeter: number = floatingElementTop + (this.containerMaxHeight ?? floatingElementHeight);
    const isfloatingElementOutsideViewport: boolean = floatingElementRightPerimeter > viewportWidth || floatingElementBottomPerimeter > viewportHeight;

    if (isfloatingElementOutsideViewport) {

      if (floatingElementRightPerimeter > viewportWidth && floatingElementBottomPerimeter < viewportHeight) {
        this.renderer.setStyle(this.elementRef.nativeElement, 'right', (viewportWidth - floatingElementLeft) + 'px');
        this.renderer.setStyle(this.elementRef.nativeElement, 'top', floatingElementTop + 'px');
      } else if (floatingElementBottomPerimeter > viewportHeight && floatingElementRightPerimeter < viewportWidth) {
        this.renderer.setStyle(this.elementRef.nativeElement, 'bottom', (viewportHeight - floatingElementTop) + 'px');
        this.renderer.setStyle(this.elementRef.nativeElement, 'left', (floatingElementLeft) + 'px');
      } else if (floatingElementRightPerimeter > viewportWidth && floatingElementBottomPerimeter > viewportHeight) {
        this.renderer.setStyle(this.elementRef.nativeElement, 'right', (viewportWidth - floatingElementLeft) + 'px');
        this.renderer.setStyle(this.elementRef.nativeElement, 'bottom', (viewportHeight - floatingElementTop) + 'px');
      }

    } else {
      this.renderer.setStyle(this.elementRef.nativeElement, 'top', floatingElementTop + 'px');
      this.renderer.setStyle(this.elementRef.nativeElement, 'left', floatingElementLeft + 'px');
    }
  }

  private clearPositioning() {
    this.renderer.removeStyle(this.elementRef.nativeElement, 'top');
    this.renderer.removeStyle(this.elementRef.nativeElement, 'left');
    this.renderer.removeStyle(this.elementRef.nativeElement, 'right');
    this.renderer.removeStyle(this.elementRef.nativeElement, 'bottom');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && this.top !== null && this.left !== null) {
      this.clearPositioning();
      this.repositionfloatingElement();
    };
  }

}
