import { Component, OnInit, ChangeDetectionStrategy, forwardRef, AfterViewInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, AbstractControl } from '@angular/forms';
import { LabelValueViewModel } from '@ezuiaws/ez.ui.models';
import { EzDatePipe, EzDatePipeFormat } from '../../pipes';
import { getCypressFieldName, getDateTimeInUserTimeZone, getDateTimeInISOFormat, isHour12 } from '../../helpers';

@Component({
  selector: 'ez-time-picker',
  templateUrl: './ez-time-picker.component.html',
  styleUrls: ['./ez-time-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EzTimePickerComponent),
      multi: true
    }
  ]
})
export class EzTimePickerComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  @Input() control: AbstractControl;
  @Input() disabled: boolean = false;
  @Input() hourFormat: '12' | '24' = isHour12() ? '12' : '24';
  @Input() placeholder: string = 'HH:MM';

  @Input() set selectedDate(value: Date | string | number) {
    if (value) {
      this._selectedDateTime = new Date(value);

      let formatDate: string;
      if (isHour12()) {
        const date: string = this.datePipe.transform(this._selectedDateTime, EzDatePipeFormat.shortTime);
        let format12Date = date.slice(0,date.length-2);
        let  meridianMode = date.slice(-2);

        formatDate = format12Date;
        this._selectedMeridianMode = meridianMode;
      } else {
        formatDate = this.datePipe.transform(this._selectedDateTime, EzDatePipeFormat.timeAsString24Hours);
      }

      let [hours, minutes]: string[] = formatDate.split(':');

      hours = hours.length === 1 ? '0' + hours : hours;
      minutes = minutes.length === 1 ? '0' + minutes : minutes;

      this._selectedTime = `${hours}:${minutes}`;
    } else {
      /*
      * Required feature to reset PrimeNG InputMask value
      * */
      this._selectedTime = this.placeholder;
      setTimeout(() => {
        this._selectedTime = '';
      }, 1);
    }
  }

  @Output() selectedDateChange: EventEmitter<Date> = new EventEmitter();

  _selectedDateTime: Date = null;
  _selectedTime: string = '';
  _selectedMeridianMode: 'am' | 'pm' | string;

  datePipe: EzDatePipe = new EzDatePipe();

  readonly meridianOptions: LabelValueViewModel[] = [
    {
      label: 'AM',
      value: 'am'
    },
    {
      label: 'PM',
      value: 'pm'
    }
  ];

  cypressName: string = '';

  onChange: any = () => {
  };
  onTouched: any = () => {
  };

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.selectedDate = new Date(getDateTimeInUserTimeZone());
  }

  ngAfterViewInit() {
    this.cypressName = getCypressFieldName(this.control);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.detectChanges();
  }

  onValueChange(value: string) {
    let date: Date = this._selectedDateTime;

    if (this.timeValueIsValid(value)) {
      let [hours, minutes]: string[] = value.split(':');
      if (this.hourFormat === '12') {
        if (hours === '12') {
          hours = '00';
        }
        if (this._selectedMeridianMode === 'pm') {
          hours = String(parseInt(hours, 10) + 12);
        }
      }
      date.setHours(+hours, +minutes);
    } else {
      date = null;
    }

    this.onTouched();
    this.selectedDateChange.emit(date);
    this.writeValue(date);
  }

  onMeridianModeChange(value: 'am' | 'pm' | string): void {
    this._selectedMeridianMode = value;
    this.onValueChange(this._selectedTime);
  }

  writeValue(value: string | number | Date): void {
    this.selectedDate = value;
    this.onChange(value ? getDateTimeInISOFormat(value) : null);
    this.cdr.markForCheck();
  }

  private timeValueIsValid(value: string): boolean {
    const [hours, minutes]: string[] = value.split(':');

    return !isNaN(+hours) && !isNaN(+minutes) &&
      (+minutes >= 0 && +minutes <= 60) &&
      (
        (+hours <= 23 && this.hourFormat === '24') ||
        (+hours <= 12 && this.hourFormat === '12')
      );
  }
}
