<!-- <button pButton
  pRipple
  type="button"
  icon='pi pi-filter'
  [pTooltip]="'ShowColorLegendAndFilters' | ezLocale"
  tooltipPosition='right'
  [ngStyle]='{position:"absolute"}'
  class="p-button-outlined p-button-success p-absolute mr-2"
  (click)="onFilterIconClick()">
</button> -->
<div class="text-center">
  <ez-carousal-date-picker (selectedDateChange)="gotoDate($event)"></ez-carousal-date-picker>
</div>

<div ezRepositionFloatingElement
  class="hover-reservation-container"
  [top]="hoveredReservation?.positionY"
  [left]="hoveredReservation?.positionX"
  [containerMaxHeight]="490"
  [@fadeInOut]='showReservationHoverModal ? "enter" : "leave"'>
  <ng-container *ngIf="showReservationHoverModal">
    <ng-content select="[hoverModal]"></ng-content>
  </ng-container>
</div>

<full-calendar #calendar 
  [events]="events" 
  [options]="calendarOptions$ | async">
</full-calendar>
