import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { CalendarOptions, DurationInput, EventClickArg, EventDropArg, Duration, EventHoveringArg } from '@fullcalendar/core';
import { ResourceInput } from '@fullcalendar/resource';
import { EventImpl } from '@fullcalendar/core/internal';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import resourceDayGridPlugin from '@fullcalendar/resource-daygrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';

import { EzCalendarDropEvent } from './models/ez-calendar-drop-event';
import { EventResizeDoneArg } from '@fullcalendar/interaction';
import { EzCalendarResizeEvent } from './models/ez-calendar-resize-event';
import { EzCalendarViewType } from './models/ez-calendar-view-type';
import { EzCalendarEvent } from './models/ez-calendar-event';
import { EzCalendarEventProps } from './models/ez-calendar-event-props';
import { EzSelectDateModel } from './models/ez-select-date-model';
import { EzCalendarHoverEvent } from './models/ez-calendar-hover-event';
import { getDateTimeInUserTimeZone, getDateTimeInISOFormat } from '../helpers';
import { EzLocaleHelper } from '@ezuiaws/ez-packages/ez-localization';

const TODAY_STR = getDateTimeInUserTimeZone();
@Injectable({
  providedIn: 'any'
})
export class EzScheduleService {

  private defaultCalendarOptions: CalendarOptions = {
    schedulerLicenseKey: '0621099241-fcs-1710199571',
    headerToolbar: {
      left: '',
      center: '',
      right: ''
    },
    aspectRatio: 3,
    initialView: EzCalendarViewType.Month,
    nowIndicator: true,
    now: TODAY_STR,
    initialDate: TODAY_STR,
    slotMinWidth: 30,
    slotDuration: '00:30:00',
    editable: true,
    droppable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    plugins: [
      dayGridPlugin,
      timeGridPlugin,
      interactionPlugin,
      resourceDayGridPlugin,
      resourceTimelinePlugin,
      listPlugin,
      resourceTimeGridPlugin
    ],
    dateClick: this.dateClick.bind(this),
    eventClick: this.eventClick.bind(this),
    eventDragStart: this.eventDragStart.bind(this),
    eventDrop: this.eventDrop.bind(this),
    eventResize: this.eventResize.bind(this),
    eventOverlap: this.eventOverlap.bind(this),
    eventMouseEnter: this.eventMouseEnter.bind(this),
    eventMouseLeave: this.eventMouseLeave.bind(this),
    slotLabelFormat: [
      this.isHour12()
        ? {
          hour: 'numeric',
          meridiem: 'short',
          hour12: true
        }
        : {
          hour: 'numeric',
          minute: '2-digit',
          hour12: false
        }
    ],
    slotLabelContent: info => info.text.replace(/24:00/g, '00:00')
  };

  private isHour12(): boolean {
    const options = new Intl.DateTimeFormat(EzLocaleHelper.locale, {
      hour: 'numeric',
    }).resolvedOptions();

    return !!options.hour12;
  }


  private customCalendarOptions: BehaviorSubject<CalendarOptions> = new BehaviorSubject<CalendarOptions>(this.defaultCalendarOptions);
  calendarOptions$: Observable<CalendarOptions> = this.customCalendarOptions.asObservable();

  private addReservationEvent: BehaviorSubject<EzCalendarEvent> = new BehaviorSubject<EzCalendarEvent>(null);
  addReservationEvent$: Observable<EzCalendarEvent> = this.addReservationEvent.asObservable();

  private editReservationEvent: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  editReservationEvent$: Observable<number> = this.editReservationEvent.asObservable();

  private dropReservationEvent: BehaviorSubject<EzCalendarDropEvent> = new BehaviorSubject<EzCalendarDropEvent>(null);
  dropReservationEvent$: Observable<EzCalendarDropEvent> = this.dropReservationEvent.asObservable();

  private dragStartReservationEvent: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  dragStartReservationEvent$: Observable<number> = this.dragStartReservationEvent.asObservable();

  private resizeReservationEvent: BehaviorSubject<EzCalendarResizeEvent> = new BehaviorSubject<EzCalendarResizeEvent>(null);
  resizeReservationEvent$: Observable<EzCalendarResizeEvent> = this.resizeReservationEvent.asObservable();

  private hoverReservationEvent: BehaviorSubject<EzCalendarHoverEvent> = new BehaviorSubject<EzCalendarHoverEvent>(null);
  hoverReservationEvent$: Observable<EzCalendarHoverEvent> = this.hoverReservationEvent.asObservable();

  get calendarOptions(): CalendarOptions {
    return this.customCalendarOptions.getValue();
  }

  set calendarOptions(options: Partial<CalendarOptions>) {
    const currentState = this.calendarOptions;
    const newState = Object.assign(currentState, options);

    this.customCalendarOptions.next(newState);
  }

  set viewType(viewType: EzCalendarViewType) {
    const currentState = this.calendarOptions;
    const newState = {
      ...currentState,
      initialView: viewType
    };

    this.customCalendarOptions.next(newState);
  }

  set resources(resources) {
    const currentState = this.calendarOptions;
    const newState = {
      ...currentState,
      resources: resources
    };

    this.customCalendarOptions.next(newState);
  }

  dateClick(selectInfo: EzSelectDateModel) {
    const slotDuration: DurationInput = selectInfo.view.calendar.getOption('slotDuration');

    let endDate: Date = selectInfo.date;
    if (typeof slotDuration === 'string') {
      const [hours, minutes, seconds] = slotDuration.split(':');
      const endDateHours: number = Number(hours) + selectInfo.date.getHours();
      const endDateMinutes: number = Number(minutes) + selectInfo.date.getMinutes();
      const endDateSeconds: number = Number(seconds) + selectInfo.date.getSeconds();

      endDate.setHours(endDateHours, endDateMinutes, endDateSeconds);
    }

    const event: EzCalendarEvent = {
      id: 0,
      resourceIds: [Number(selectInfo.resource.id)],
      title: null,
      start: selectInfo.dateStr,
      end: getDateTimeInISOFormat(endDate)
    };
    this.addReservationEvent.next(event);

    const calendarApi = selectInfo.view.calendar;
    calendarApi.unselect(); // clear date selection
  }

  eventClick(clickInfo: EventClickArg) {
    this.editReservationEvent.next(Number(clickInfo.event.id));

    const calendarApi = clickInfo.view.calendar;
    calendarApi.unselect(); // clear date selection
  }

  eventDrop(eventDropInfo: EventDropArg) {
    const event: EventImpl = eventDropInfo.event;
    const relatedEvents: EventImpl[] = eventDropInfo.relatedEvents;
    const oldResource: ResourceInput = eventDropInfo.oldResource;
    const newResource: ResourceInput = eventDropInfo.newResource;
    const delta: Duration = eventDropInfo.delta;
    // if getParent() is null it is a resource group
    const hasParent: boolean = !!eventDropInfo.newResource?.getParent();
    //if the new resource is null then its a move on the same resource
    const oldAndNewResourceIsTheSame: boolean = !(!!eventDropInfo.newResource);
    const isResourceGroup: boolean = !(hasParent) && !(oldAndNewResourceIsTheSame);
    const revertFunction: Function = eventDropInfo.revert;

    const dropEvent: EzCalendarDropEvent = {
      eventId: Number(event.id),
      oldResourceId: oldResource ? Number(oldResource.id) : null,
      newResourceId: newResource ? Number(newResource.id) : null,
      newStartDateTime: event.start,
      deltaMilliseconds: delta.milliseconds,
      relatedEventsIds: relatedEvents.map(el => Number(el.id)),
      isResourceGroup: isResourceGroup,
      revert: revertFunction
    };

    this.dropReservationEvent.next(dropEvent);
  }

  eventDragStart(clickInfo: EventClickArg) {
    this.dragStartReservationEvent.next(Number(clickInfo.event.id));
  }

  eventResize(eventInfo: EventResizeDoneArg) {
    const startDelta: Duration = eventInfo.startDelta;
    const endDelta: Duration = eventInfo.endDelta;
    const relatedEvents: EventImpl[] = eventInfo.relatedEvents;
    const event: EventImpl = eventInfo.event;
    const eventProps = <EzCalendarEventProps>eventInfo.event.extendedProps?.eventProps;
    const revertFunction: Function = eventInfo.revert;

    if (eventProps.canResize) {
      const resizeEvent: EzCalendarResizeEvent = {
        eventId: Number(event.id),
        startDeltaMilliseconds: startDelta.milliseconds,
        endDeltaMilliseconds: endDelta.milliseconds,
        relatedEventsIds: relatedEvents.map(el => Number(el.id)),
        event: eventProps,
        revert: revertFunction
      };
      this.resizeReservationEvent.next(resizeEvent);
    } else {
      revertFunction();
    }
  }

  eventMouseEnter(eventHoverInfo: EventHoveringArg) {
    const hoverEvent: EzCalendarHoverEvent = {
      eventId: Number(eventHoverInfo.event.id),
      positionX: eventHoverInfo.jsEvent.x,
      positionY: eventHoverInfo.jsEvent.y
    };
    this.hoverReservationEvent.next(hoverEvent);
  }

  eventMouseLeave() {
    this.hoverReservationEvent.next(null);
  }

  eventOverlap(eventInfo) {
  }
}
