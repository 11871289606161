import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { style, animate, transition, trigger, state } from '@angular/animations';
import { Observable } from 'rxjs';
import { debounceTime, filter, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CalendarOptions, Calendar } from '@fullcalendar/core';
import { FullCalendarComponent } from '@fullcalendar/angular';

import { EzScheduleService } from '../../ez-schedule.service';
import { EzCalendarDropEvent } from '../../models/ez-calendar-drop-event';
import { EzCalendarResizeEvent } from '../../models/ez-calendar-resize-event';
import { EzCalendarViewType } from '../../models/ez-calendar-view-type';
import { EzCalendarEvent } from '../../models/ez-calendar-event';
import { EzCalendarHoverEvent } from '../../models/ez-calendar-hover-event';
import { EzCalendarScheduleGroup } from '../../models/ez-calendar-schedule-group';
import { waitUntilElementsArrayExistAndAddClass } from '../../../helpers';

@UntilDestroy()
@Component({
  selector: 'ez-calendar',
  templateUrl: './ez-calendar.component.html',
  styleUrls: ['./ez-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [EzScheduleService],
  animations: [
    trigger('fadeInOut', [
      state('enter', style({
        opacity: 1
      })),
      state('leave', style({
        opacity: 0
      })),
      transition('enter => leave', animate('300ms')),
      transition('leave => enter', animate('300ms'))
    ])
  ],
})
export class EzCalendarComponent implements OnInit, AfterViewInit {

  @ViewChild('calendar') calendar: FullCalendarComponent;

  @Input() set calendarOptions(options: Partial<CalendarOptions>) {
    if (options !== null) {
      this.scheduleService.calendarOptions = options;
    }
  }

  @Input() set initialView(initialView: EzCalendarViewType) {
    if (initialView !== null) {
      this.scheduleService.viewType = initialView;
    }
  }

  @Input() set resources(resources: EzCalendarScheduleGroup[]) {
    this.scheduleService.resources = resources;
  }

  @Input() events: EzCalendarEvent[];

  @Input() refetch$: Observable<void> = new Observable<void>();

  @Input() showReservationHoverModal: boolean = false;

  @Input() hoveredReservation: EzCalendarHoverEvent = null;

  @Output() gotoDateEvent: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() newReservationEvent: EventEmitter<EzCalendarEvent> = new EventEmitter<EzCalendarEvent>();
  @Output() editReservationEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() dropReservationEvent: EventEmitter<EzCalendarDropEvent> = new EventEmitter<EzCalendarDropEvent>(null);
  @Output() dragStartReservationEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() resizeReservationEvent: EventEmitter<EzCalendarResizeEvent> = new EventEmitter<EzCalendarResizeEvent>(null);
  @Output() hoverReservationEvent: EventEmitter<EzCalendarHoverEvent> = new EventEmitter<EzCalendarHoverEvent>(null);

  calendarOptions$: Observable<CalendarOptions> = this.scheduleService.calendarOptions$;

  private fullCalendarApi: Calendar;

  constructor(private scheduleService: EzScheduleService) { }

  ngOnInit(): void {
    this.refetch$.pipe(
      untilDestroyed(this)
    ).subscribe(() => {
      this.fullCalendarApi.refetchEvents();
    });
    this.scheduleService.addReservationEvent$.pipe(
      filter(event => !!event),
      untilDestroyed(this)
    ).subscribe((event: EzCalendarEvent) => {
      this.newReservationEvent.emit(event);
    });
    this.scheduleService.editReservationEvent$.pipe(
      filter(event => !!event),
      untilDestroyed(this)
    ).subscribe((event: number) => {
      this.editReservationEvent.emit(event);
    });
    this.scheduleService.dropReservationEvent$.pipe(
      filter(event => !!event),
      untilDestroyed(this)
    ).subscribe((event: EzCalendarDropEvent) => {
      this.dropReservationEvent.emit(event);
    });
    this.scheduleService.dragStartReservationEvent$.pipe(
      filter(event => !!event),
      untilDestroyed(this)
    ).subscribe((event: number) => {
      this.dragStartReservationEvent.emit(event);
    });
    this.scheduleService.resizeReservationEvent$.pipe(
      filter(event => !!event),
      untilDestroyed(this)
    ).subscribe((event: EzCalendarResizeEvent) => {
      this.resizeReservationEvent.emit(event);
    });
    this.scheduleService.hoverReservationEvent$.pipe(
      debounceTime(100),
      untilDestroyed(this)
    ).subscribe((event: EzCalendarHoverEvent) => {
      this.hoverReservationEvent.emit(event);
    });
  }

  ngAfterViewInit(): void {
    this.fullCalendarApi = this.calendar.getApi();
    this.resizeCalendar();
  }

  changeView(viewName: string): void {
    this.fullCalendarApi.changeView(viewName);
  }

  gotoDate(date: Date) {
    this.fullCalendarApi.gotoDate(date);
    const currentDate: Date = this.fullCalendarApi.getDate();

    this.gotoDateEvent.emit(currentDate);
  }

  dateIncrement(deltaInput: any) {
    this.fullCalendarApi.incrementDate(deltaInput);
    const currentDate: Date = this.fullCalendarApi.getDate();

    this.gotoDateEvent.emit(currentDate);
  }

  // onFilterIconClick() {
  //   // TODO TBD
  // }

  zoomIn() {
    const currentSlotWidth = this.fullCalendarApi.getOption('slotMinWidth');
    this.fullCalendarApi.setOption('slotMinWidth', currentSlotWidth + 10);
  }

  zoomOut() {
    const currentSlotWidth = this.fullCalendarApi.getOption('slotMinWidth');
    if (currentSlotWidth > 20) {
      this.fullCalendarApi.setOption('slotMinWidth', currentSlotWidth - 10);
    } else {
      this.fullCalendarApi.setOption('slotMinWidth', 20);
    }
  }

  private resizeCalendar(): void {
    this.calendarOptions$.pipe(
      filter(calendarOptions => Array.isArray(calendarOptions.resources) && calendarOptions.resources.length > 0),
      take(1)
    ).subscribe(calendarOptions => {
      let res: EzCalendarScheduleGroup[] = [...calendarOptions.resources as EzCalendarScheduleGroup[]];
      let longestTitle: number = 18;
      for (let i = 0; i < res.length; i++) {
        longestTitle = longestTitle > res[i].title.length ? longestTitle : res[i].title.length;
        waitUntilElementsArrayExistAndAddClass(`[data-resource-id="${res[i].id}"]`, "resource-group-bg");
        for (let y = 0; y < res[i].children.length; y++) {
          longestTitle = longestTitle > res[i].children[y].title.length ? longestTitle : res[i].children[y].title.length;
        }
      }
      if (longestTitle > 45) {
        longestTitle = 35;
      }
      this.fullCalendarApi.setOption('resourceAreaWidth', `${longestTitle * 0.6}rem`);
    });
  }

}
